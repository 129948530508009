<template>
    <b-card title="Order Details">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Date</span>
            </th>
            <td class="pb-50">
               {{orderData.created_at | moment('YY-MM-DD HH:mm:ss')}}
            </td>

            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
                  <b-badge :variant="status[1][orderData.status]" style="cursor: pointer;">
                        {{ status[0][orderData.status] }}
                  </b-badge>
            </td>

            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Number of Product</span>
            </th>
            <td class="pb-50 text-capitalize">
                  {{orderData.order_products.length}}
            </td>
          </tr>


          <tr>
            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold"> Total Product Amount </span>
            </th>
            <td class="pb-50 text-capitalize">
                {{ Number(orderData.total_product_amount)}}
            </td>

            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold"> Total Service Amount </span>
            </th>
            <td class="pb-50 text-capitalize">
                {{ Number(orderData.total_service_amount)}}
            </td>

            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold"> Sub Total </span>
            </th>
            <td class="pb-50 text-capitalize">
                {{ Number(orderData.total_product_amount) + Number(orderData.total_service_amount) }}  
            </td>

          </tr>


          <tr>
            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold"> Coupon Amount </span>
            </th>
            <td class="pb-50 text-capitalize">
                {{ orderData.coupon_amount}}
            </td>

            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold"> Additional Service Total  </span>
            </th>
            <td class="pb-50 text-capitalize">
                <b-badge variant="light-primary">
                    {{ orderData.total_additional_service_amount}}
                </b-badge>
            </td>

            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold"> Total  </span>
            </th>
            <td class="pb-50 text-capitalize">
                <b-badge variant="light-primary">
                    {{ orderData.total_amount}}
                </b-badge>
            </td>

           

          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold"> Address  </span>
            </th>
            <td class="pb-50 text-capitalize">
               
                    {{ orderData.address}}
                
            </td>
          </tr>
 
          

        </table>
    </b-card>
</template>


<script>
import { BCard, BBadge } from 'bootstrap-vue'

export default {
    components : {
        BCard,
        BBadge
    },
    props : {
      orderData: {
        type: [Object, Array],
        required: true,
      }
    },
    data() {
        return {
          status: [{
        completed: 'completed', Confirmed: 'Confirmed', canceled: 'canceled', pending: 'pending', arrived: 'arrived',
      },
      {
        completed: 'light-primary', Confirmed: 'light-success', canceled: 'light-danger', pending: 'light-warning', arrived: 'light-info',
      }],
        }
    },
}
</script>