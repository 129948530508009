<template>
  <b-tabs>
    <b-tab active title="Machines">
      <machine :machineData="machineData" />
    </b-tab>
    <b-tab title="Orders">
      <order :machineData="orderData" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import Machine from './machine.vue'
import Order from './orders.vue'
export default {
  components: {
    BTabs,
    BCardText,
    BTab,

    Machine, 
    Order
  },
  props: {
    machineData: {
      type: [Array, Object],
      default: () => {},
    },
    orderData: {
      type: [Array, Object],
      default: () => {},
    },
  },
}
</script>