<template>
  <b-card
    no-body
  >

    <b-table
      striped
      responsive
      :items="machineData"
      :fields="tableColumns"
      class="mb-0"
    >

      <template #cell(id)="data">
          {{data.index + 1}}
      </template>

      <template #cell(created_at)="data" >
          {{data.value | moment('YY-MM-DD HH:mm:ss')}}
      </template>

      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>


      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card>
          <strong>
            Products
          </strong>
          <b-row class="mb-2" v-for="(product, index) in row.item.order_products" :key="index">
            <b-col  md="3" class="mb-1">
              <strong>Product Name : </strong>{{ product.product_name }}
            </b-col>
            <b-col  md="3" class="mb-1">
              <strong>Amount : </strong>{{ product.product_amount }}
            </b-col>
            <b-col  md="3" class="mb-1">
              <strong>Service Amount : </strong>{{ product.service_amount }}
            </b-col>
            <b-col  md="3" class="mb-1">
              <strong>Total Amount : </strong>{{ product.total_amount }}
           </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col md="3" class="mb-1">
              <strong>Request Date : </strong>{{ row.item.date_ordered | moment('YY-MM-DD HH:mm:ss') }}
            </b-col>
            <b-col md="3" class="mb-1">
              <strong>Confirm Date : </strong>{{ row.item.date_confirm | moment('YY-MM-DD HH:mm:ss') }}
            </b-col>
            <b-col md="3" class="mb-1">
              <strong>Arrive Date : </strong>{{ row.item.date_arrive | moment('YY-MM-DD HH:mm:ss') }}
            </b-col>
            <b-col md="3" class="mb-1" v-if="row.item.status = 'canceled'">
              <strong>Cancel Date : </strong>{{ row.item.date_cancel | moment('YY-MM-DD HH:mm:ss') }}
            </b-col>

          </b-row>

          <b-row class="mb-2">
            <b-col md="3" class="mb-1">
              <strong>Coupon Mode : </strong>{{ row.item.coupom_code }}
            </b-col>
            <b-col md="3" class="mb-1">
              <strong>Coupon Amount : </strong>{{ row.item.coupon_amount }}
            </b-col>
            <b-col md="3" class="mb-1">
              <strong>Sub Amount : </strong>{{ row.item.sub_amount }}
            </b-col>
           <b-col md="3" class="mb-1">
              <strong>Total Amount : </strong>{{ row.item.total_amount }}
            </b-col>

          </b-row>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Hide Details
          </b-button>
        </b-card>
      </template>

      

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable,BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BBadge, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BBadge, BRow, BCol, BButton,
  },
  props: {
    machineData: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data(){
      return {
            tableColumns: [
                  { key: 'show_details', label: 'Details'},
                  { key: 'id', sortable: true },
                  { key: 'payment_method.name', label: 'Payment Methods', sortable: true },
                  // { key: 'year', label: 'Year', sortable: true },
                  { key: 'total_amount', label: 'Total Amount', sortable: true },
                  { key: 'status', label: 'Status', sortable: true },
                  { key: 'created_at', label: 'Created At', sortable: true },
                  { key: 'address', label: 'Address' },
            ],
            status: [{
              completed: 'completed', 2: 'Professional', canceled: 'canceled', pending: 'pending', 5: 'Applied',
            },
            {
              completed: 'light-primary', 2: 'light-success', canceled: 'light-danger', pending: 'light-warning', 5: 'light-info',
            }],
            }
  }

}
</script>

<style>

</style>
