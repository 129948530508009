<template>
 <b-card title="Additional Service">
    <b-table responsive :items="data"  :fields="tableColumns"> 
        <template #cell(id)="data">
            {{ data.index + 1 }}
        </template>

        <template #cell(created_at)="data" >
            {{data.value | moment('YY-MM-DD HH:mm:ss')}}
        </template>
    </b-table>   
 </b-card>
</template>


<script>
import { BCard } from 'bootstrap-vue'
import { BTable } from 'bootstrap-vue'
import { number } from 'echarts/lib/export';
export default {
    components: {
        BCard,
        BTable
    },
    props : {
        data: {
            type: [Array, Object],
            required: true,
        }
    },
    data() {
        return {
            tableColumns: [
                { key: 'id', sortable: true },
                { key: 'additional_service_name', label: 'Name', sortable: true },
                { key: 'amount', label: 'Amount', sortable: true},
                { key: 'quantity', label: 'Quantity', sortable: true },
                { key: 'total_amount', label: 'Total Amount', sortable: true },
        ],
        }
    },
    // computed: {
    //     totalAmount() {
    //         return this.data.reduce((acc, item) => acc + Number(item.product_amount), 0);
    //     },
    //     totalServiceAmount() {
    //         return this.data.reduce((acc, item) => acc + Number(item.service_amount), 0);
    //     },
    //     total() {
    //         return this.data.reduce((acc, item) => acc + Number(item.product_amount) + Number(item.service_amount), 0);
    //     },

    // }
}
</script>