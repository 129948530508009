<template>

  <b-card title="Order journey">
    <app-timeline>
    <app-timeline-item
      title="Created"
      subtitle="Order reqeted at"
      :time="orderData.date_ordered"
      variant="success"
      icon="AwardIcon"
    />

    <app-timeline-item
      title="Confirmed"
      subtitle="workshop confirmed at"
      :time="orderData.date_confirm"
      variant="info"
      icon="InfoIcon"
    />

    <app-timeline-item
      title="Arrival"
      subtitle="workshop arrival at"
      :time="orderData.date_arrive"
      variant="warning"
      icon="MapPinIcon"
    />

    <app-timeline-item
      title="Finshed"
      subtitle="workshop finshed at"
      :time="orderData.date_finished"
      icon="XCircleIcon"
    />

     <app-timeline-item
      title="Canceled"
      subtitle="Canceled order at"
      :time="orderData.date_cancel"
      icon="XCircleIcon"
    />

     

    
  </app-timeline>
  </b-card>

</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'


export default {
    components : {
        BCard,
        AppTimeline,
        AppTimelineItem,
    },
    props : {
      orderData: {
        type: [Object, Array],
        required: true,
      }
    },
}
</script>