<template>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >

    <b-row>
      <b-col
          cols="12"
          lg="12"
        >
        <order-data   :orderData="orderData" />
      </b-col>
    </b-row>

    <b-row>
      <b-col
          cols="12"
          lg="6"
        >
          <customer-info :userData="userData" />
        </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <work-shop :userData="workShop" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="4">
        <order-status :orderData="orderData" />
      </b-col>

      <b-col cols="12" lg="8">

        <b-row>
          <b-col cols="12">
            <order-products :orderProducts="orderProducts" />
          </b-col>

          <b-col cols="12">
            <additional-serviec :data="orderAdditionalService" />
          </b-col>

        </b-row>

      </b-col>


      AdditionalServiec
    </b-row>
  

  <!-- <b-card>
    <tab  :machineData="machineData" :orderData="orderData"/>
  </b-card> -->

</b-overlay>

</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, BOverlay
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DataService  from './data/services'
import Tab from './profile/tab'

import customerInfo from './components/customerInfo.vue'
import workShop from './components/workShop.vue'
import orderStatus from './components/orderStatus.vue'
import orderProducts from './components/orderProducts.vue'
import orderData from './components/orderData.vue'
import AdditionalServiec from './components/additionalServiec.vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BOverlay,
    ToastificationContent,
    Tab,
    customerInfo,
    workShop,
    orderStatus,
    orderProducts,
    orderData,
    AdditionalServiec,
  },

  data() {
    return {
      userData: {},
      machineData: [],
      workShop: {},
      orderData: [],
      isLoading: true,
      orderProducts: {},
      orderAdditionalService: {},
      status: [
        {
          1: 'Actvie', 0: 'Inactive',
        },
        {
          1: 'light-success', 0: 'light-danger', 
        }
      ],
    }
  },
  methods: {
      getData(){
            this.isLoading = true;
            DataService.get(this.$route.params.id)
            .then(response => {
                  this.isLoading = false
                  this.userData    = response.data.customer
                  this.workShop = response.data.work_shop
                  this.orderData   = response.data
                  this.orderProducts = response.data.order_products
                  this.orderAdditionalService = response.data.additional_service
                  this.$toast({
                        component: ToastificationContent,
                        props: {
                              title: 'successfully',
                              icon: 'BellIcon',
                              text: response.data.responseDescription,
                              variant: 'success',
                        },
                  })
            })
      },
      changeStatus(id){
            this.isLoading = true;
            DataService.changeStatus(id)
            .then(response => {
            this.isLoading = false
            if(this.userData.status == 1){
                  this.userData.status = 0
            }else{
                  this.userData.status = 1
            }
            this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'successfully',
                  icon: 'BellIcon',
                  text: response.data.responseDescription,
                  variant: 'success',
                  },
            })
            
            
            })
      },
      
  },
  mounted() {
    this.getData()
  },
  
}
</script>

<style>

</style>
